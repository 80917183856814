import React from "react";
import PropTypes from "prop-types";
import { useAppContext } from "fusion-context";

/**
 * Create a HabitTiles (App only) component.
 * */
export const HabitTiles = () => {
  // NOTE: Show placeholder in the admin
  const { isAdmin } = useAppContext();
  if (isAdmin) {
    return (
      <div className="center pb-sm" data-testid="habit-tiles-placeholder">
        <strong>Habit Tiles</strong> (Apps only)
      </div>
    );
  }

  return null;
};

HabitTiles.label = "HabitTiles";
HabitTiles.propTypes = {
  isAdmin: PropTypes.bool
};

export default HabitTiles;
